<script>
import TextField from '../inputs/text.vue'

export default {
  name: 'Address',
  extends: TextField,
  props: {
    label: {
      type: String,
      default: 'Address'
    },
    name: {
      type: String,
      default: 'address'
    },
    rules: {
      type: String,
      default: 'required|min:5'
    }
  }
}
</script>
